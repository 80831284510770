<template>
  <v-card tile elevation="1" class="d-flex flex-column flex-md-row h-100">
    <v-img class="img-pdv" alt="Immagine punto vendita" :src="srcImage"></v-img>
    <div
      class="w-100 d-flex flex-column text-center text-md-left align-center align-md-start"
    >
      <v-card-title>
        <h2>
          {{ title }}
        </h2>
      </v-card-title>
      <v-card-text class="text-body-2" v-html="text"> </v-card-text>
      <v-card-actions v-if="buttonLink">
        <v-btn large color="secondary" class="px-6" depressed :to="buttonLink">
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>
<style scoped lang="scss">
.img-pdv {
  max-width: 50%;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: none;
  }
}
.v-card__title {
  word-break: break-word;
  color: var(--v-secondary-base);
}
.v-list-item__subtitle {
  color: $text-color;
}
</style>
<script>
import AddressService from "~/service/addressService";

import get from "lodash/get";

export default {
  name: "WarehouseCard",
  props: {
    srcImage: { type: String },
    title: { type: String },
    text: { type: String },
    buttonLink: { type: String },
    buttonText: { type: String }
  },
  data() {
    return {
      warehouseList: []
    };
  },
  methods: {
    getImagePdvSrv(warehouse) {
      return get(warehouse, "metaData.warehouse_info.IMAGE_WAREHOUSE");
    },
    async findWarehouse() {
      let data = await AddressService.findWarehouse({
        province_id: this.provinceIdParsed
      });
      this.warehouseList = data.warehouses;
    }
  },
  mounted() {
    if (this.provinceId != undefined) {
      this.provinceIdParsed = parseInt(this.provinceId);
    }
    this.findWarehouse();
  }
};
</script>
