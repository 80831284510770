<template>
  <div
    v-if="product && product.priceDisplay && product.available > 0"
    class="actions my-1"
  >
    <ProductPrice :product="product" />
    <ProductQty
      :selectedOptions="selectedOptions"
      :product="product"
      :item="item"
      :full="full"
    />
  </div>
  <div
    v-else
    class="actions justify-center text-center text-caption error--text font-weight-bold"
  >
    {{ $t("product.notAvailable") }}
  </div>
</template>
<style scoped lang="scss">
.actions {
  min-height: 58px;
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
<script>
import ProductPrice from "./ProductPrice.vue";
import ProductQty from "./ProductQty.vue";
import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import ProductService from "~/service/productService";

export default {
  name: "ProductActions",
  props: {
    productId: { type: Number, required: true },
    full: { type: Boolean, required: false }
  },
  components: {
    ProductPrice,
    ProductQty
  },
  mixins: [productMixin],
  data() {
    return {
      product: null,
      selectedOptions: {}
    };
  },
  async created() {
    let result = await ProductService.getProductsByIds([this.productId]);
    this.product = result.products[0];
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    }
  }
};
</script>
