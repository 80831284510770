<template>
  <div
    class="qty-wrap rounded-pill"
    :class="{
      'not-empty': quantity > 0,
      'no-border': quantity < 1,
      'full-qty-wrap': full
    }"
    @mousedown.stop
  >
    <a
      role="button"
      class="minus rounded-circle"
      v-ripple
      @click.stop.prevent="minus(true, { infos: { ...selectedOptions } })"
      @mousedown.stop
    >
      <!-- v-ripple -->
      <v-icon>$minus</v-icon>
    </a>
    <div class="val-cnt">
      <span class="val">{{ quantity }} {{ unit }}</span>
      <span class="small">{{ quantityPerUnit }}</span>
    </div>
    <a
      role="button"
      class="plus"
      :class="{ ' rounded-circle': !full, 'rounded-pill': full }"
      v-ripple
      @click.stop.prevent="plus(true, { infos: { ...selectedOptions } })"
    >
      <!-- v-ripple -->
      <v-icon v-if="quantity > 0">$plus</v-icon>
      <template v-else>
        <v-icon>$cart</v-icon>
        <span v-if="full" class="font-weight-bold white--text text-uppercase">
          {{ $t("products.addToCart") }}
        </span>
      </template>
    </a>
  </div>
</template>
<style lang="scss">
.full-qty-wrap {
  width: 267px;
  .plus {
    width: 267px;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";

export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    full: { type: Boolean, default: false },
    selectedOptions: { type: Object }
  },
  // eslint-disable-next-line vue/no-unused-components
  mixins: [productMixin],
  computed: {
    packageCount() {
      if (this.product.productInfos.TIPOLOGIA != "Sfuso" && this.item) {
        if (
          this.product.logisticPalletItems > 0 &&
          this.item.quantity % this.product.logisticPalletItems == 0
        ) {
          return (
            this.item.quantity / this.product.logisticPalletItems + " pallet"
          );
        } else if (
          this.product.logisticPackagingItems > 0 &&
          this.item.quantity % this.product.logisticPackagingItems == 0
        ) {
          let unit = this.item.quantity / this.product.logisticPackagingItems;
          return "(" + unit + (unit == 1 ? " collo" : " colli") + ")";
        }
      }
      return "(0 colli)";
    }
  }
};
</script>
