<template>
  <div class="warehouse-list">
    <v-row no-gutters>
      <v-col
        class="py-0 py-md-3"
        order="2"
        order-sm="1"
        cols="12"
        sm="4"
        xl="3"
      >
        <v-list
          class="warehouse-list-list"
          :height="$vuetify.breakpoint.smAndUp ? mapHeight : ''"
        >
          <v-list-item dense>
            <v-list-item-title>
              Servizi:
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            v-for="service in services"
            :key="service.serviceId"
          >
            <v-list-item-content class="py-0">
              <v-checkbox
                color="secondary"
                v-model="service.active"
                :false-value="false"
                :true-value="true"
                :label="service.name"
                @change="toggleGeoJson(service)"
              ></v-checkbox>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        class="py-0 py-md-3"
        order="1"
        order-sm="2"
        cols="12"
        sm="8"
        xl="9"
      >
        <GmapMap
          ref="mapRef"
          :center="{ lat: 45.5, lng: 11.3 }"
          :zoom="$vuetify.breakpoint.xsOnly ? 7 : 8"
          :style="{ width: mapWidth, height: mapHeight }"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false
          }"
        >
          <GmapMarker
            v-for="(warehouse, i) in allWarehouseList"
            :ref="'warehousMarker_' + warehouse.warehouseId"
            :key="warehouse.warehouseId"
            :position="
              google &&
                new google.maps.LatLng(
                  warehouse.address.latitude,
                  warehouse.address.longitude
                )
            "
            :clickable="true"
            icon="/pdv/pin.png"
            @click="toggleInfoWindow(warehouse, i)"
          />
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick.stop="infoWinOpen = false"
          >
            {{ selectedWarehouse }}
            Contenuto
          </gmap-info-window>
        </GmapMap>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
.warehouse-list-list {
  height: 380px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: initial;
  }
  overflow-y: auto;
  // .v-list-item {
  //   .v-input {
  //     margin-top: 0px;
  //   }
  // }

  .v-list-item__title {
    text-transform: uppercase;
    color: var(--v-secondary-base);
    font-size: 16px;
    font-weight: bold;
  }
  .v-list-item__subtitle {
    color: $text-color;
  }
  .v-avatar {
    padding: 2px;
    margin: 0;
    img {
      width: 24px;
      height: 34px;
    }
  }
}
#infoWindowDialog {
  padding: 10px;
  cursor: pointer;
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #515151;
  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-secondary-base);
  }
}
</style>
<script>
import AddressService from "~/service/addressService";

import forEach from "lodash/forEach";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseServiceMap",
  data() {
    return {
      services: [
        {
          name: "Tosano Drive",
          serviceId: 1,
          active: true,
          warehouseList: []
        },
        {
          name: "Tosano Delivery",
          serviceId: 2,
          active: true
        },
        {
          name: "Tosano Locker",
          serviceId: 4,
          active: true,
          warehouseList: []
        }
      ],
      warehouseList: [],
      lockerList: [],
      deliveryGeoJson: null,
      geoJsonLayer: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return "480px";
    },
    allWarehouseList() {
      let activeWhArray = this.services
        .filter(service => service.active == true && service.serviceId != 2)
        .map(service => service.warehouseList);
      return [].concat(...activeWhArray);
    }
  },
  methods: {
    async toggleGeoJson(service) {
      if (service.serviceId == 2) {
        this.$refs.mapRef.$mapPromise.then(map => {
          this.geoJsonLayer.setMap(service.active ? map : null);
        });
      }
    },
    async findWarehouse() {
      let data = await AddressService.findWarehouse();
      let driveService = this.services.find(service => service.serviceId == 1);
      driveService.warehouseList = data.warehouses;
    },
    async findLocker() {
      this.lockerList = [];
      let data = await AddressService.getAddressByDeliveryService(4);
      forEach(data.addresses, a => {
        this.lockerList.push({ metaData: {}, address: a });
      });
      let lockerService = this.services.find(service => service.serviceId == 4);
      lockerService.warehouseList = this.lockerList;
    },
    async loadGeoJson() {
      this.deliveryGeoJson = await AddressService.getGeoServiceCoverage();
      this.geoJsonLayer = new this.google.maps.Data();
      this.geoJsonLayer.addGeoJson(this.deliveryGeoJson);
      this.geoJsonLayer.setStyle({
        fillColor: "#e31f24",
        strokeWeight: 2
      });
      this.$refs.mapRef.$mapPromise.then(map => {
        this.geoJsonLayer.setMap(map);
      });
    },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },
    toggleInfoWindow: function(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };
      let metaData = warehouse.metaData.warehouse_info;
      let phoneNumber = metaData && metaData.PHONE ? metaData.PHONE : " -- ";
      // let email = metaData && metaData.EMAIL ? metaData.EMAIL : " --- ";
      // let emailDelivery =
      // metaData && metaData.EMAIL_DELIVERY ? metaData.EMAIL_DELIVERY : " --- ";
      this.infoOptions.content =
        "<div id='infoWindowDialog' style='font-family: Poppins'>" +
        "<h3 class='d-flex align-center mb-3'><img class='mr-2' src='/pdv/pin.png' alt='pin negozio' />" +
        (warehouse.name || warehouse.address.addressName) +
        "</h3>" +
        "<div class='mb-3'>" +
        warehouse.address.address1 +
        " " +
        warehouse.address.addressNumber +
        "<br/>" +
        warehouse.address.city +
        " (" +
        warehouse.address.province +
        ") " +
        warehouse.address.postalcode +
        "<br/>" +
        "Telefono: " +
        phoneNumber +
        "<br/>" +
        // "Email: <span class='text-decoration-underline'>" +
        // email +
        // "</span><br/>" +
        // "Email delivery: <span class='text-decoration-underline'>" +
        // emailDelivery +
        // "</span><br/>" +
        "</div>" +
        "<a class='secondary--text font-weight-bold text-decoration-none' target='system' href='https://www.google.it/maps/dir//" +
        warehouse.name +
        "@/" +
        warehouse.address.latitude +
        "," +
        warehouse.address.longitude +
        "'>COME ARRIVARE</a>" +
        "</div>";

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    }
  },
  mounted() {
    this.findWarehouse();
    this.findLocker();
    this.loadGeoJson();
  }
};
</script>
