var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-list"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"2","order-sm":"1","cols":"12","sm":"4","xl":"3"}},[_c('v-list',{staticClass:"warehouse-list-list",attrs:{"height":_vm.$vuetify.breakpoint.smAndUp ? _vm.mapHeight : ''}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" Servizi: ")])],1),_vm._l((_vm.services),function(service){return _c('v-list-item',{key:service.serviceId,attrs:{"dense":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-checkbox',{attrs:{"color":"secondary","false-value":false,"true-value":true,"label":service.name},on:{"change":function($event){return _vm.toggleGeoJson(service)}},model:{value:(service.active),callback:function ($$v) {_vm.$set(service, "active", $$v)},expression:"service.active"}})],1)],1)})],2)],1),_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"2","cols":"12","sm":"8","xl":"9"}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{ lat: 45.5, lng: 11.3 },"zoom":_vm.$vuetify.breakpoint.xsOnly ? 7 : 8,"options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false
        }}},[_vm._l((_vm.allWarehouseList),function(warehouse,i){return _c('GmapMarker',{key:warehouse.warehouseId,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
              new _vm.google.maps.LatLng(
                warehouse.address.latitude,
                warehouse.address.longitude
              ),"clickable":true,"icon":"/pdv/pin.png"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedWarehouse)+" Contenuto ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }