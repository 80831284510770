var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-7"},[_c('h2',[_vm._v(_vm._s(_vm.$t("addresses.autoDiscovery.title")))]),_c('div',{staticClass:"d-flex"},[(_vm.googleEnabled)?_c('vue-google-autocomplete',{ref:"address",attrs:{"id":"map","classname":"service-discovery","placeholder":_vm.$t('addresses.autoDiscovery.placeholder'),"enable-geolocation":true,"country":"it"},on:{"placechanged":_vm.getAddressData}}):_vm._e()],1),(_vm.results)?_c('v-card',{staticClass:"results mt-2"},[_c('v-list',[_c('v-subheader',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.deliveryService"))+" ")])]),_c('v-list-item',[_c('v-list-item-icon',{staticClass:"d-flex align-center service-icon-wrapper"},[_c('img',{staticClass:"service-icon logo-home",attrs:{"src":_vm.$t('navbar.service.2.icon', {
                color: this.dataValidated.active == 1 ? 'color' : 'grey'
              }),"alt":"Logo Casa"}})]),_c('v-list-item-content',[(this.dataValidated.active == 1)?_c('v-list-item-title',{staticClass:"auto-discovery-title"},[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.addressOk"))+" ")]):_c('v-list-item-title',{staticClass:"auto-discovery-title"},[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.addressKo"))+" ")])],1),_c('v-list-item-action',[(_vm.dataValidated.active == 1)?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.handleAddressCreation(_vm.dataValidated)}}},[_c('v-icon',{staticClass:"d-inline d-sm-none"},[_vm._v("$location")]),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.addAddressButton"))+" ")])],1):_vm._e()],1)],1),_c('v-subheader',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.driveService"))+" ")])]),(_vm.warehouses.addresses.length == 0)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"d-flex align-center service-icon-wrapper"},[_c('img',{staticClass:"service-icon logo-home",attrs:{"src":_vm.$t('navbar.service.1.icon', {
                color: 'grey'
              }),"alt":"Logo Casa"}})]),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.noStoresFound")))])],1):_vm._e(),_vm._l((_vm.warehouses.addresses),function(warehouse){return _c('v-list-item',{key:warehouse.addressId,attrs:{"two-line":""}},[_c('v-list-item-icon',{staticClass:"d-flex align-center service-icon-wrapper"},[_c('img',{staticClass:"service-icon",attrs:{"src":_vm.$t('navbar.service.1.icon', { color: 'color' }),"alt":"Logo drive"}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"auto-discovery-title"},[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + warehouse.addressTypeId + ".format", warehouse ))+" ")]),_c('v-list-item-subtitle',{staticClass:"auto-discovery-subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + warehouse.addressTypeId + ".list", warehouse )))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.handleWarehouseClick(warehouse.addressType, warehouse.addressId)}}},[_c('v-icon',{staticClass:"d-inline d-sm-none"},[_vm._v("$location")]),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.start"))+" ")])],1)],1)],1)}),_c('v-subheader',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.lockerService"))+" ")])]),(_vm.lockers.addresses.length == 0)?_c('v-list-item',[_c('v-list-item-icon',{staticClass:"d-flex align-center service-icon-wrapper"},[_c('img',{staticClass:"service-icon logo-home",attrs:{"src":_vm.$t('navbar.service.4.icon', {
                color: 'grey'
              }),"alt":"Logo Casa"}})]),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.noLockersFound"))+" ")])],1):_vm._e(),_vm._l((_vm.lockers.addresses),function(locker){return _c('v-list-item',{key:locker.addressId,attrs:{"two-line":""}},[_c('v-list-item-icon',{staticClass:"d-flex align-center service-icon-wrapper"},[_c('img',{staticClass:"service-icon locker-logo",attrs:{"src":_vm.$t('navbar.service.4.icon', { color: 'color' }),"alt":"Logo locker"}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"auto-discovery-title"},[_vm._v(" "+_vm._s(_vm.$t("navbar.address." + locker.addressTypeId + ".format", locker))+" ")]),_c('v-list-item-subtitle',{staticClass:"auto-discovery-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("navbar.address." + locker.addressTypeId + ".list", locker)))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.handleWarehouseClick(locker.addressType, locker.addressId)}}},[_c('v-icon',{staticClass:"d-inline d-sm-none"},[_vm._v("$location")]),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" "+_vm._s(_vm.$t("addresses.autoDiscovery.start"))+" ")])],1)],1)],1)})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }