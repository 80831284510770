<template>
  <div class="profile">
    <v-container>
      <component
        :is="templateName"
        :key="key"
        :page="page"
        :additionalData="additionalData"
      ></component>
    </v-container>
  </div>
</template>
<style lang="scss">
img.size-full {
  max-width: 100%;
  height: auto;
}
.lh-28 {
  line-height: 28px;
}
</style>
<script>
import Static from "@/components/wordpress/Static.vue";

import CMSService from "~/service/cmService";

import startCase from "lodash/startCase";
import get from "lodash/get";

export default {
  data() {
    return {
      page: {},
      key: 1,
      additionalData: null
    };
  },
  created() {
    if (this.$route.params.additionalData) {
      this.additionalData = this.$route.params.additionalData;
    }
    this.fetchPage(this.$route.params.pageName);
  },
  components: { Static },
  computed: {
    templateName() {
      if (this.page.template) {
        let templateName = startCase(this.page.template);
        return templateName;
      } else {
        return "Static";
      }
    }
  },
  methods: {
    async fetchPage(pageName) {
      let data = await CMSService.getPage(pageName);
      if (data.length > 0) {
        this.page = data[0];
        this.key++;
      }
    }
  },
  watch: {
    "$route.params.pageName": function(pageName) {
      this.fetchPage(pageName);
    }
  },
  metaInfo() {
    return {
      title: get(this.page, "yoast_head_json.title", "Pagina statica"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "yoast_head_json.description")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: get(this.page, "yoast_head_json.og_locale", "IT_it")
        },
        {
          property: "og:title",
          content: get(
            this.page,
            "yoast_head_json.description",
            "La Tua Spesa - Tosano"
          )
        },
        {
          property: "og:type",
          content: get(this.page, "yoast_head_json.og_type", "article")
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" +
            window.location.host +
            (global.config.socialLogo || "/logo/social-logo.png")
        },
        {
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          property: "og:site_name",
          content: get(this.page, "yoast_head_json.og_site_name", "Tosano")
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: get(
            this.page,
            "yoast_head_json.twitter_card",
            "La Tua Spesa - Tosano"
          )
        },
        {
          name: "twitter:title",
          content: get(
            this.page,
            "yoast_head_json.og_title",
            "La Tua Spesa - Tosano"
          )
        },
        {
          name: "twitter:image",
          content:
            "https://" +
            window.location.host +
            (global.config.socialLogo || "/logo/social-logo.png")
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["https://google.com/logo.jpg"],
        author: {
          "@type": "Organization",
          name: "Tosano"
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: "Tosano",
          logo: {
            "@type": "ImageObject",
            url: "https://google.com/logo.jpg"
          }
        }
      };
    }
  }
};
</script>
