var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-list"},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.showListParsed)?_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"2","order-sm":"1","cols":"12","sm":_vm.showListParsed ? '4' : '0',"xl":_vm.showListParsed ? '3' : '0'}},[_c('v-list',{staticClass:"warehouse-list-list",attrs:{"height":_vm.mapHeight}},_vm._l((_vm.warehouseList),function(warehouse){return _c('v-list-item',{key:warehouse.warehouseId,attrs:{"two-line":"","dense":""},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse)}}},[_c('v-list-item-avatar',{attrs:{"rounded":"0","max-width":"24px","max-height":"34px","min-height":"34px","min-width":"24px"}},[_c('img',{attrs:{"src":"/pdv/pin.png","alt":"pin negozio"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(warehouse.name || warehouse.address.addressName))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(warehouse.address.address1)+","+_vm._s(warehouse.address.addressNumber)+" "),_c('br'),_vm._v(" "+_vm._s(warehouse.address.city)+" ("+_vm._s(warehouse.address.province)+") ")])],1)],1)}),1)],1):_vm._e(),_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"2","cols":"12","sm":_vm.showListParsed ? '8' : '0',"xl":_vm.showListParsed ? '9' : '0'}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{ lat: 45.5, lng: 11.3 },"zoom":_vm.$vuetify.breakpoint.xsOnly ? 7 : 8,"options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false
        }}},[_vm._l((_vm.warehouseList),function(warehouse,i){return _c('GmapMarker',{key:warehouse.warehouseId,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
              new _vm.google.maps.LatLng(
                warehouse.address.latitude,
                warehouse.address.longitude
              ),"clickable":true,"icon":"/pdv/pin.png"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedWarehouse)+" Contenuto ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }