<template>
  <v-container>
    <h1 v-if="title" v-html="title"></h1>
    <v-runtime-template :template="template" @click="clicked" />
  </v-container>
</template>
<script>
import NinjaForm from "~/components/ninjaForms/NinjaForm.vue";
import WarehouseCards from "@/components/wordpress/WarehouseCards.vue";
import WarehouseMap from "@/components/wordpress/WarehouseMap.vue";
import WarehouseCard from "@/components/wordpress/WarehouseCard.vue";
import ShopAtWorkSubscription from "@/components/wordpress/ShopAtWorkSubscription.vue";
import CitiesList from "@/views/wordpress/CitiesList.vue";
import GeocodeMap from "@/views/wordpress/GeocodeMap.vue";
import cookieBotCookiePolicy from "@/components/wordpress/cookieBotCookiePolicy.vue";
import WarehouseServiceMap from "@/components/wordpress/WarehouseServiceMap.vue";
import ServiceAutodiscovery from "@/components/wordpress/ServiceAutodiscovery.vue";

import {
  VContainer,
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle
} from "vuetify/lib";

import VRuntimeTemplate from "v-runtime-template";

import get from "lodash/get";

import clickHandler from "~/mixins/clickHandler";
// import page from "~/mixins/page";
import ProductListSlider from "@/components/wordpress/WpProductListSlider.vue";
import ProductActions from "@/components/product/ProductActions.vue";

export default {
  name: "Static",
  mixins: [clickHandler],
  data() {
    return {
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VContainer,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    WarehouseCards,
    WarehouseMap,
    WarehouseServiceMap,
    WarehouseCard,
    ShopAtWorkSubscription,
    NinjaForm,
    CitiesList,
    GeocodeMap,
    ProductListSlider,
    ProductActions,
    cookieBotCookiePolicy,
    ServiceAutodiscovery
  },
  props: { page: { type: Object, required: true } },
  computed: {
    deliveryServiceId() {
      return get(this.page.deliveryserviceid);
    },
    title() {
      return this.page.hide_title == "1"
        ? null
        : get(this.page, "title.rendered");
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.page && this.page.content) {
        this.template = `<div>${this.page.content.rendered}</div>`;
      }
    });
  }
};
</script>
