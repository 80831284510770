<template>
  <v-container>
    <div class="warehouse-list">
      <v-row>
        <v-col
          v-for="warehouse in warehouseList"
          :key="warehouse.warehouseId"
          cols="12"
          sm="6"
        >
          <WarehouseCard
            :srcImage="getImagePdvSrv(warehouse)"
            :title="warehouse.address.addressName"
            :text="$t('warehouseCards.location', warehouse.address)"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import AddressService from "~/service/addressService";
import WarehouseCard from "./WarehouseCard.vue";

import get from "lodash/get";

export default {
  name: "WarehouseCards",
  props: {
    provinceId: { type: String, default: undefined }
  },
  components: {
    WarehouseCard
  },
  data() {
    return {
      warehouseList: []
    };
  },
  methods: {
    getImagePdvSrv(warehouse) {
      return get(warehouse, "metaData.warehouse_info.IMAGE_WAREHOUSE");
    },
    async findWarehouse() {
      let data = await AddressService.findWarehouse({
        province_id: this.provinceIdParsed
      });
      this.warehouseList = data.warehouses;
    }
  },
  mounted() {
    if (this.provinceId != undefined) {
      this.provinceIdParsed = parseInt(this.provinceId);
    }
    this.findWarehouse();
  }
};
</script>
